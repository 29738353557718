export const UrlConfig = {
  staging: {
    backend_url: "https://api.theeliteqa.com/api/",
    recruiter_web_url: "https://staging.dev.theeliteqa.com/dashboard",
    super_admin_web_url: "https://staging.superadmin.theeliteqa.com/dashboard",
  },
  production: {
    backend_url: "https://api.theeliteqa.com/api/",
    recruiter_web_url: "https://dev.theeliteqa.com/dashboard",
    super_admin_web_url: "https://superadmin.theeliteqa.com/dashboard",
  },
};
