
import React from 'react'
import { Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode';

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

const deleteAllCookie = () => {
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            let p = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            };
            d.shift();
        }
    }
}


const CheckLoginRoute = ({ children }) => {
  
    const token = getCookie('Xh7ERL0G')
    
    
    if(token!==null && token !==undefined){
        const decoded = jwt_decode(token);
        const expirationTime = (decoded.exp * 1000)- 60000;
        
        if(Date.now() <= expirationTime) {
            if(decoded.access === 'superadmin'){
                return window.open("https://superadmin.theeliteqa.com//dashboard", "_self");
                
            }

            if(decoded.access === 'recruiter'){
                return window.open("https://www.dev.theeliteqa.com//dashboard", "_self")
                //return window.open("http://localhost:3001/dashboard", "_self")
      
            }
        }else{
        localStorage.clear()
        deleteAllCookie()
        return <Navigate to="/login" />;
        }
    }
    // decode the jwt token and check the expiration time
   
    // if token is not there redirect to login or token expired
     return children
}

export default CheckLoginRoute